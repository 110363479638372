/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar, Toast } from "primereact";
import VStockDropdown from "components/v-stock";
import AllotmentService from "services/allotments";
import { showToast } from "utils/common";
import { InputTextarea } from "primereact/inputtextarea";

const ImportExcel = () => {
  const defaultFields = [
    { field: "", header: "User" },
    { field: "", header: "Amount" },
  ];

  const [importedData, setImportedData] = useState([]);
  const [importedCols, setImportedCols] = useState(defaultFields);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const fileRef = useRef(null);

  const toCapitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const defaultState = {
    data: null,
    token: null,
    type: null,
    description: "",
  };

  const [allotment, setAllotment] = useState(defaultState);

  const onChange = (val, name) => {
    let _details = { ...allotment };
    _details[`${name}`] = val;
    setAllotment(_details);
  };

  const importExcelFunc = (e) => {
    const file = e.files[0];
    import("xlsx").then((xlsx) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const wb = xlsx.read(e.target.result, { type: "array" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
        // Prepare DataTable
        const cols: any = data[0];
        data.shift();
        let _importedCols = cols.map((col) => ({
          field: col,
          header: toCapitalize(col),
        }));
        let _importedData = data.map((d) => {
          return cols.reduce((obj, c, i) => {
            obj[c] = d[i];
            return obj;
          }, {});
        });

        setImportedCols(_importedCols);
        setImportedData(_importedData.filter((e) => e.user && e.amount));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const clear = () => {
    setImportedData([]);
    setImportedCols(defaultFields);
    fileRef.current.clear();
    setAllotment(defaultState);
  };

  const submit = async () => {
    try {
      setLoading(true);
      await AllotmentService.createAllotments({
        body: {
          ...allotment,
          data: importedData,
        },
      });
      clear();
      showToast(toast, "success", "Success");
      setLoading(false);
    } catch (error) {
      showToast(toast, "error", error.errors);
      setLoading(false);
    }
  };

  const cancelOptions = {
    label: "Cancel",
    icon: "pi pi-times",
    className: "p-button-danger",
  };

  return (
    <div className="grid">
      <Toast ref={toast}></Toast>
      <div className="col-12">
        <div className="flex align-items-center justify-content-center py-2">
          <FileUpload
            ref={fileRef}
            chooseOptions={{
              label: "Import file allotment",
              icon: "pi pi-file-excel",
              className: "p-button-success",
            }}
            mode="basic"
            progressBarTemplate={
              loading && <ProgressBar mode="indeterminate" />
            }
            name="demo[]"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className="mr-2"
            uploadHandler={importExcelFunc}
            customUpload={true}
            cancelOptions={cancelOptions}
          />
        </div>
      </div>

      <div className="col-4"></div>
      <div className="field col-12 md:col-4">
        <VStockDropdown
          className="w-full"
          value={allotment.token}
          setValue={(v) => onChange(v, "token")}
        />
      </div>
      <div className="col-4"></div>

      <div className="col-4"></div>
      <div className="field col-12 md:col-4">
        <Dropdown
          className="w-full"
          value={allotment.type}
          options={[
            {
              name: "id",
              value: "id",
            },
            {
              name: "email",
              value: "email",
            },
            {
              name: "wallet",
              value: "wallet",
            },
          ]}
          onChange={(e) => onChange(e.value, "type")}
          optionLabel="name"
          placeholder="Select type"
        />
      </div>
      <div className="col-4"></div>

      <div className="col-4"></div>
      <div className="field col-12 md:col-4">
        <InputTextarea
          className="w-full"
          placeholder="Description..."
          value={allotment.description}
          onChange={(e) => onChange(e.target.value, "description")}
        />
      </div>
      <div className="col-4"></div>

      <div className="col-12 md:col-12 text-center">
        <Button
          icon="pi pi-check"
          type="button"
          label="Submit"
          className="p-button-info mr-2"
          loading={loading}
          onClick={(_) => submit()}
        />
        <Button
          type="button"
          label="Clear"
          icon="pi pi-times"
          onClick={clear}
          className="p-button-danger ml-2"
        />
      </div>

      <div className="col-12">
        <DataTable
          value={importedData}
          emptyMessage="No data"
          paginator
          totalRecords={importedData.length}
          rowsPerPageOptions={[10, 20, 50, 100]}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          rows={10}
          alwaysShowPaginator={false}
          responsiveLayout="scroll"
        >
          {importedCols.map((col, index) => (
            <Column key={index} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default ImportExcel;
