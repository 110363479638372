/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useContext,
} from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { MasterContext } from "contexts/MasterContext";
import { Button, Calendar, InputSwitch, MultiSelect, Panel } from "primereact";
import "../styles.scss";
import InvestService from "services/invests";
import { nanoid } from "nanoid";
import VUpload from "components/v-upload";
import VReward from "components/v-reward";
import VAffilate from "components/v-affiliate";
import VUserDropdown from "components/v-user";
import VLocation from "components/v-location";
import { v4 as uuidv4 } from "uuid";
import Service from "services/categories";
import VSelectUsers from "components/v-select-users";

const TYPES = [
  { value: "bronze" },
  { value: "silver" },
  { value: "gold" },
  { value: "platinum" },
  { value: "diamond" },
  { value: "vip" },
  { value: "saphire" },
];

const Details = (props, ref) => {
  const { data, reload, toast, setLoading } = props;

  const { objects, stocks, groups } = useContext(MasterContext);

  const [categories, setCategories] = useState(null);

  const emptyData = {
    user_id: "",
    name: "",
    description: "",
    type: "",
    status: "opening",
    categories: "",
    website: "",
    url_task: "",
    background: "",
    video_background: "",
    backgroundSuccess: "",
    backgroundRewards: "",
    logoLevel: "",
    icon: "",
    type_lock: "month",
    image: "",
    video: "",
    promotionImage: "",
    filePromotion: null,
    order: 0,
    count: {
      amount_invested: 0,
    },
    reward: {
      stocks: [],
      vouchers: [],
      tickets: [],
      codes: [],
      products: [],
    },
    time: {
      start_date: null,
      end_date: null,
      promotion_date: null,
    },
    invest: { from: 0, to: 0, stock: "" },
    require: {
      max_per_user: 1,
      total_package: 1,
      total_amount: 0,
      min_withdraw_profit: 0,
      max_withdraw_profit: 0,
      is_enable_max_withdraw: false,
      is_enable_claim: false,
    },
    profits: [],
    objects: [],
    groups: [],
    affiliates: {},
    affiliates_profit: {},
    address: {
      country: null,
      province: null,
      district: null,
      ward: null,
    },
    branch: [],
    allocation_users: [],
    allocation_location: [],
    is_affiliate_profit: false,
    is_machingbonus_leader: false,
    is_machingbonus_province: false,
    is_claim_maching_bonus: false,
    is_claim_dividend: false,
    category_id: null,
    dividend: {
      percent: 0,
      stock_claim: "",
      total: 0,
      claimed: 0,
      remain: 0,
    },
  };

  const [details, setDetails] = useState(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const res: any = await Service.getAll();
      if (res) {
        setCategories(res);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (data)
      setDetails({
        ...emptyData,
        ...data,
        user_id: data.user_id?._id,
        time: {
          start_date: new Date(data.time.start_date),
          end_date: new Date(data.time.end_date),
          promotion_date: new Date(data.time.promotion_date),
        },
        allocation_location: data.allocation_location || [],
        allocation_users: data.allocation_users || [],
      });
  }, [data]);

  useEffect(() => {
    const _total =
      ((details?.count?.amount_invested || 0) * details?.dividend?.percent) /
      100;
    const _remain = _total - (details?.dividend?.claimed || 0);
    setDetails((prev: any) => ({
      ...prev,
      dividend: {
        ...prev.dividend,
        total: _total,
        remain: _remain,
      },
    }));
  }, [details?.dividend?.percent]);

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[`${field}`][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const handleAddProfit = () => {
    setDetails((prev) => ({
      ...prev,
      profits: [
        ...prev.profits,
        { index: nanoid(), time_lock: 0, stock: "", percent: 0 },
      ],
    }));
  };

  const handleSubProfit = () => {
    setDetails((prev) => ({
      ...prev,
      profits: prev.profits.slice(0, prev.profits.length - 1),
    }));
  };

  const handleAddTokenLocation = () => {
    setDetails((prev) => ({
      ...prev,
      allocation_location: [
        ...prev.allocation_location,
        {
          id: uuidv4(),
          address: {
            country: "",
            province: "",
          },
          amount: 0,
          amount_trusted: 0,
        },
      ],
    }));
  };

  const handleSubTokenLocation = () => {
    setDetails((prev) => ({
      ...prev,
      allocation_location: prev.allocation_location.slice(
        0,
        prev.allocation_location?.length - 1
      ),
    }));
  };

  const handleAddAllocationUser = () => {
    setDetails((prev) => ({
      ...prev,
      allocation_users: [
        ...prev.allocation_users,
        {
          id: "",
          amount: 0,
          amount_trusted: 0,
        },
      ],
    }));
  };

  const handleSubAllocationUser = () => {
    setDetails((prev) => ({
      ...prev,
      allocation_users: prev.allocation_users.slice(
        0,
        prev.allocation_users?.length - 1
      ),
    }));
  };

  const handleChangeProfits = (index) => (name, value) => {
    let _profits = details.profits.map((p) =>
      p.index === index
        ? {
            ...p,
            [name]: value,
          }
        : p
    );
    setDetails((prev) => ({
      ...prev,
      profits: _profits,
    }));
  };

  const onChangeTokenForLocation = (field: string, value: any, id: string) => {
    const tokenLocation = details.allocation_location;
    const index = tokenLocation.findIndex((item) => item?.id === id);
    tokenLocation[index][field] = value;
    setDetails((prev) => ({
      ...prev,
      token_for_location: tokenLocation,
    }));
  };

  const onChangeTokenForUser = (field: string, value: any, id: string) => {
    const allotcationUser = details.allocation_users;
    const index = allotcationUser.findIndex((item) => item?.id === id);
    allotcationUser[index][field] = value;
    setDetails((prev) => ({
      ...prev,
      allocation_users: allotcationUser,
    }));
  };

  // const importExcelFunc = (e) => {
  //   const file = e.files[0];
  //   import("xlsx").then((xlsx) => {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const wb = xlsx.read(e.target.result, { type: "array" });
  //       const wsname = wb.SheetNames[0];
  //       const ws = wb.Sheets[wsname];
  //       const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });
  //       data.shift();
  //       const dataNoEmpty = data
  //         .map((i, index) => {
  //           if (i.length > 0) {
  //             return [...i];
  //           }
  //         })
  //         .filter(Boolean);
  //       const _importedData = dataNoEmpty.map((row: any) => {
  //         return row[0];
  //       });
  //       onChange(_importedData.toString(), "allocation_users");
  //     };

  //     reader.readAsArrayBuffer(file);
  //   });
  // };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await InvestService.update({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await InvestService.create({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  return (
    <>
      <Panel header="General" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="user">User</label>
            <VUserDropdown
              value={details.user_id}
              setValue={(user) => onChange(user, "user_id")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Name</label>
            <InputText
              id="name"
              value={details.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="website">Website</label>
            <InputText
              id="website"
              value={details.website}
              onChange={(e) => onChange(e.target.value, "website")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="video">Video ID</label>
            <InputText
              id="video"
              value={details.video}
              onChange={(e) => onChange(e.target.value, "video")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="video">Order</label>
            <InputText
              id="order"
              value={details.order}
              onChange={(e) => onChange(e.target.value, "order")}
              required
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="status">Type</label>
            <Dropdown
              value={details.type}
              options={TYPES}
              onChange={(e) => onChange(e.value, "type")}
              optionLabel="value"
              optionValue="value"
              placeholder="Select type"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="status">Status</label>
            <Dropdown
              value={details.status}
              options={[
                {
                  label: "opening",
                  value: "opening",
                },
                {
                  label: "pending",
                  value: "pending",
                },
                {
                  label: "closed",
                  value: "closed",
                },
              ]}
              onChange={(e) => onChange(e.value, "status")}
              optionLabel="label"
              placeholder="Select status"
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="start_date">Start Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={
                details.time.start_date
                  ? new Date(details.time.start_date)
                  : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "start_date", "time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="end_date">End Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={
                details.time.end_date
                  ? new Date(details.time.end_date)
                  : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "end_date", "time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="promotion_date">Promotion Date</label>
            <Calendar
              className="m-1"
              id="time24"
              placeholder="Date"
              value={
                details.time.promotion_date
                  ? new Date(details.time.promotion_date)
                  : undefined
              }
              dateFormat="mm-dd-yy"
              onChange={(e: any) => onChange(e.value, "promotion_date", "time")}
              showTime
              showSeconds
              showButtonBar
            />
          </div>
          <div className="field col-12">
            <label htmlFor="category">Category</label>
            <Dropdown
              filter
              value={details.category_id}
              options={categories
                ?.filter((a) => a.group === "category_invest")
                .map((el) => {
                  return {
                    label: el.name,
                    value: el._id,
                  };
                })}
              onChange={(e) => onChange(e.value, "category_id")}
              optionLabel="label"
              placeholder="Select category"
            />
          </div>
        </div>
      </Panel>
      <Panel header="Filter" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Groups</label>
            <MultiSelect
              value={details.groups}
              options={groups}
              onChange={(e) => onChange(e.value, "groups")}
              optionLabel="name"
              optionValue="key"
              placeholder="Select a group"
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="objects">Objects</label>
            <MultiSelect
              value={details.objects}
              options={objects}
              onChange={(e) => onChange(e.value, "objects")}
              optionLabel="name"
              optionValue="id"
              placeholder="Select a object"
            />
          </div>

          {/* <div className="field col-12 md:col-12">
            <label htmlFor="user">Apply Users</label>
            <InputTextarea
              value={details.allocation_users}
              onChange={(e) => onChange(e.target.value, "allocation_users")}
              rows={10}
            ></InputTextarea>

            <div className="flex align-items-center justify-content-center py-2">
              <FileUpload
                ref={fileRef}
                chooseOptions={{
                  label: "Import file",
                  icon: "pi pi-file-excel",
                  className: "p-button-success",
                }}
                mode="basic"
                // progressBarTemplate={
                //   loading && <ProgressBar mode="indeterminate" />
                // }
                name="demo[]"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className="mr-2"
                uploadHandler={importExcelFunc}
                customUpload={true}
              />
            </div>
          </div> */}

          <div className="field col-12 md:col-12">
            <label htmlFor="user">Branch User</label>
            <VSelectUsers
              value={details.branch}
              onChange={(e) => onChange(e.target.value, "branch")}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Token Allocation For User"
        toggleable
        className="my-2"
        collapsed={true}
      >
        {details?.allocation_users?.map((item, index) => (
          <div className="grid">
            <div className="field col-6">
              <label htmlFor="user">ID User</label>
              <InputText
                id="name"
                value={item.id}
                onChange={(e) =>
                  onChangeTokenForUser("id", e.target.value, item?.id)
                }
                required
              />
            </div>
            <div className="field col-3">
              <label htmlFor="from">Max Amount</label>
              <InputNumber
                id="from"
                value={item?.amount}
                onChange={(e) =>
                  onChangeTokenForUser("amount", e.value, item?.id)
                }
                required
              />
            </div>
            <div className="field col-3">
              <label htmlFor="from">Trusted Amount</label>
              <InputNumber id="from" value={item?.amount_trusted} disabled />
            </div>
          </div>
        ))}

        <div className="col-12">
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={handleAddAllocationUser}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={handleSubAllocationUser}
          />
        </div>
      </Panel>

      <Panel
        header="Token For Location"
        toggleable
        className="my-2"
        collapsed={true}
      >
        {details?.allocation_location?.map((item, index) => (
          <div className="grid">
            <div className="field col-6">
              <label htmlFor="user">Address</label>
              <VLocation
                address={item?.address}
                setAddress={(a) =>
                  onChangeTokenForLocation("address", a, item?.id)
                }
                isShowDistrict={false}
                isShowWard={false}
              />
            </div>
            <div className="field col-3">
              <label htmlFor="from">Max Token</label>
              <InputNumber
                id="from"
                value={item?.amount}
                onChange={(e) =>
                  onChangeTokenForLocation("amount", e.value, item?.id)
                }
                required
              />
            </div>
            <div className="field col-3">
              <label htmlFor="from">Trusted Token</label>
              <InputNumber id="from" value={item?.amount_trusted} disabled />
            </div>
          </div>
        ))}

        <div className="col-12">
          <Button
            icon="bx bxs-add-to-queue"
            className="p-button-rounded p-button-success"
            aria-label="Search"
            onClick={handleAddTokenLocation}
          />
          <Button
            icon="bx bxs-minus-circle"
            className="p-button-rounded p-button-danger"
            aria-label="Cancel"
            onClick={handleSubTokenLocation}
          />
        </div>
      </Panel>

      <Panel header="Investment" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-4">
            <label htmlFor="website">Stock</label>
            <Dropdown
              defaultValue={details.invest.stock}
              value={details.invest.stock}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock", "invest")}
              optionLabel="fullName"
              filterBy="fullName"
              optionValue="symbol"
              filter
              showClear
              placeholder="Select stock"
            />
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="from">Amount From</label>
            <InputNumber
              id="from"
              value={details.invest.from}
              onChange={(e) => onChange(e.value, "from", "invest")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="to">Amount To</label>
            <InputNumber
              id="to"
              value={details.invest.to}
              onChange={(e) => onChange(e.value, "to", "invest")}
              required
            />
          </div>
        </div>
      </Panel>

      <Panel header="Information" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="website">Type Lock</label>
            <Dropdown
              value={details.type_lock}
              options={[
                {
                  name: "month",
                  value: "month",
                },
                {
                  name: "week",
                  value: "week",
                },
                {
                  name: "day",
                  value: "day",
                },
              ]}
              onChange={(e) => onChange(e.value, "type_lock")}
              optionLabel="name"
              filterBy="name"
              optionValue="value"
              showClear
              placeholder="Type Lock"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="total_package">Total package</label>
            <InputNumber
              id="total_package"
              value={details.require.total_package}
              onChange={(e) => onChange(e.value, "total_package", "require")}
              required
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="total_package">Total Amount</label>
            <InputNumber
              id="total_amount"
              value={details.require.total_amount}
              onChange={(e) => onChange(e.value, "total_amount", "require")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="max_per_user">Maximum per user</label>
            <InputNumber
              id="max_per_user"
              value={details.require.max_per_user}
              onChange={(e) => onChange(e.value, "max_per_user", "require")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="min_withdraw_profit">Min withdraw profit</label>
            <InputNumber
              id="min_withdraw_profit"
              value={details.require.min_withdraw_profit}
              onChange={(e) =>
                onChange(e.value, "min_withdraw_profit", "require")
              }
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="max_withdraw_profit">Max withdraw profit</label>
            <InputNumber
              id="max_withdraw_profit"
              value={details.require.max_withdraw_profit}
              onChange={(e) =>
                onChange(e.value, "max_withdraw_profit", "require")
              }
              required
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_affiliate_profit">Is Affiliate Profit</label>
            <br />
            <InputSwitch
              checked={details.is_affiliate_profit}
              onChange={(e) => onChange(e.value, "is_affiliate_profit")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_affiliate_profit">Is Claim Maching Bonus</label>
            <br />
            <InputSwitch
              checked={details.is_claim_maching_bonus}
              onChange={(e) => onChange(e.value, "is_claim_maching_bonus")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_enable_claim">Is Claim</label>
            <br />
            <InputSwitch
              checked={details.require.is_enable_claim}
              onChange={(e) => onChange(e.value, "is_enable_claim", "require")}
            />
          </div>{" "}
          <div className="field col-6 md:col-3">
            <label htmlFor="is_claim_dividend">Is Claim Dividend</label>
            <br />
            <InputSwitch
              checked={details.is_claim_dividend}
              onChange={(e) => onChange(e.value, "is_claim_dividend")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_enable_max_withdraw">
              Is Maching bonus leader
            </label>
            <br />
            <InputSwitch
              checked={details.is_machingbonus_leader}
              onChange={(e) => onChange(e.value, "is_machingbonus_leader")}
            />
          </div>
          <div className="field col-6 md:col-3">
            <label htmlFor="is_enable_max_withdraw">
              {" "}
              Is Maching bonus province
            </label>
            <br />
            <InputSwitch
              checked={details.is_machingbonus_province}
              onChange={(e) => onChange(e.value, "is_machingbonus_province")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Profits" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          {details.profits.map((profit) => {
            return (
              <>
                <div className="field col-12 md:col-6" key={profit.index}>
                  <label htmlFor="time_lock">Time Lock</label>
                  <InputNumber
                    id="time_lock"
                    value={profit.time_lock}
                    onChange={(e) =>
                      handleChangeProfits(profit.index)("time_lock", e.value)
                    }
                    required
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="time_lock">Percent</label>
                  <InputNumber
                    id="time_lock"
                    value={profit.percent}
                    onChange={(e) =>
                      handleChangeProfits(profit.index)("percent", e.value)
                    }
                    required
                    prefix="%"
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="website">Stock To Get Bonus</label>
                  <Dropdown
                    defaultValue={profit.stock}
                    value={profit.stock}
                    options={stocks}
                    onChange={(e) =>
                      handleChangeProfits(profit.index)("stock", e.value)
                    }
                    optionLabel="fullName"
                    filterBy="fullName"
                    optionValue="symbol"
                    filter
                    showClear
                    placeholder="Select stock"
                  />
                </div>
                <div className="field col-12 md:col-6">
                  <label htmlFor="website">Stock To Claim</label>
                  <Dropdown
                    defaultValue={profit.stock_claim}
                    value={profit.stock_claim}
                    options={stocks}
                    onChange={(e) =>
                      handleChangeProfits(profit.index)("stock_claim", e.value)
                    }
                    optionLabel="fullName"
                    filterBy="fullName"
                    optionValue="symbol"
                    filter
                    showClear
                    placeholder="Select stock"
                  />
                </div>
                <hr />
              </>
            );
          })}
          <div className="col-12">
            <Button
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success"
              aria-label="Search"
              onClick={handleAddProfit}
            />
            <Button
              icon="bx bxs-minus-circle"
              className="p-button-rounded p-button-danger"
              aria-label="Cancel"
              onClick={handleSubProfit}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Images" toggleable className="my-2" collapsed={true}>
        <div className="grid">
          <div className="field col-12 md:col-6">
            <VUpload
              label="Background"
              urlFile={details.background}
              setUrlFile={(file) => onChange(file, "background")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Video Background"
              urlFile={details.video_background}
              fileType="video"
              setUrlFile={(file) => onChange(file, "video_background")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Logo"
              urlFile={details.icon}
              setUrlFile={(file) => onChange(file, "icon")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <VUpload
              label="Information"
              urlFile={details.image}
              setUrlFile={(file) => onChange(file, "image")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Banner Congratulations"
              urlFile={details.backgroundSuccess}
              setUrlFile={(file) => onChange(file, "backgroundSuccess")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Rewards congratulations"
              urlFile={details.backgroundRewards}
              setUrlFile={(file) => onChange(file, "backgroundRewards")}
            />
          </div>

          <div className="field col-12 md:col-6">
            <VUpload
              label="Logo Level"
              urlFile={details.logoLevel}
              setUrlFile={(file) => onChange(file, "logoLevel")}
            />
          </div>
          <div className="field col-12 md:col-6">
            <VUpload
              label="Promotion"
              urlFile={details.promotionImage}
              setUrlFile={(file) => onChange(file, "promotionImage")}
            />
          </div>
        </div>
      </Panel>

      <Panel header="Reward" toggleable className="my-2" collapsed={true}>
        <VReward
          reward={details.reward}
          setReward={(reward) => onChange(reward, "reward")}
        />
      </Panel>

      <Panel header="Affilate" toggleable className="my-2" collapsed={true}>
        <VAffilate
          affiliates={details.affiliates}
          setAffiliates={(a) => onChange(a, "affiliates")}
        />
      </Panel>
      {details.is_affiliate_profit && (
        <Panel
          header="Affilate Maching Bonus"
          toggleable
          className="my-2"
          collapsed={true}
        >
          <VAffilate
            affiliates={details.affiliates_profit}
            setAffiliates={(a) => onChange(a, "affiliates_profit")}
          />
        </Panel>
      )}
      <Panel header="Dividend" toggleable className="my-2" collapsed={true}>
        <div className="grid grid-cols-12">
          <div className="field col-12 md:col-6">
            <label htmlFor="time_lock">Percent</label>
            <InputNumber
              value={details?.dividend?.percent}
              onChange={(e) => onChange(e.value, "percent", "dividend")}
              required
              prefix="%"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="website">Stock claim</label>
            <Dropdown
              defaultValue={details?.dividend?.stock_claim}
              value={details?.dividend?.stock_claim}
              options={stocks}
              onChange={(e) => onChange(e.value, "stock_claim", "dividend")}
              optionLabel="fullName"
              filterBy="fullName"
              optionValue="symbol"
              filter
              showClear
              placeholder="Select stock"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="total">Total</label>
            <InputNumber value={details?.dividend?.total} disabled />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="claimed">Claimed</label>
            <InputNumber value={details?.dividend?.claimed} disabled />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="remain">Remain</label>
            <InputNumber value={details?.dividend?.remain} disabled />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default forwardRef(Details);
