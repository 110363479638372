import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

type IProps = {
  type?: string;
  data?: any;
  setData?: (value: any) => void;
};

const Actions = (props: IProps) => {
  const { type, data, setData } = props;

  return (
    <div className="grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="title">Pass withdraw</label>
        <InputText
          value={data.pass}
          onChange={(e: any) =>
            setData((prev) => ({
              ...prev,
              pass: e.target.value,
            }))
          }
        />
      </div>
      {type === "decline" && (
        <div className="field col-12">
          <label htmlFor="title">Note</label>
          <InputTextarea
            value={data.note}
            onChange={(e: any) =>
              setData((prev) => ({
                ...prev,
                note: e.target.value,
              }))
            }
          />
        </div>
      )}
    </div>
  );
};

export default Actions;
