/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { AppTopbar } from "../../layouts/AppTopbar";
import { AppFooter } from "../../layouts/AppFooter";
import { AppMenu } from "../../layouts/AppMenu";
import { AppConfig } from "../../layouts/AppConfig";

import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";

import Dashboard from "./dashboards";
import Empty from "./empty";
import Users from "./users";
import { MasterContext } from "contexts/MasterContext";
import { useContext } from "react";
import Kycs from "./kycs";
import Verifies from "./verifies";
import Uploads from "./uploads";
import Supports from "./supports";
import Balances from "./balances";
import Positions from "./settings/positions";
import TransactionInvestments from "./transactions/investments";
import TransactionProfitInvestments from "./transactions/profit-investments";
import TransactionChanges from "./transactions/changes";
import TransactionSwaps from "./transactions/swaps";
import TransactionDeposits from "./transactions/deposits";
import TransactionWithdraws from "./transactions/withdraws";
import SettingObjects from "./settings/objects";
import StocksRequests from "./requests";
import Stocks from "./stocks";
import Notifications from "./notifications";
import Allotments from "./allotments";
import Products from "./products";
import BanksAndWallets from "./settings/banks";
import StocksPrice from "./settings/stocks-price";
import Logos from "./settings/logos";
import Channels from "./settings/channels/channel";
import Social from "./settings/channels/social";
import Referrals from "./settings/referrals";
import LandingPages from "./settings/landing-pages";
import PaymentDeposits from "./payments/deposits";
import PaymentWithdraws from "./payments/withdraws";
import Packages from "./packages";
import CampaignsList from "./campaigns/list";
import Banners from "./banners";
import QuestionAndAnswer from "./q&a";
import WhitePaper from "./white-paper";
import Policy from "./policy";
import Events from "./events";
import Codes from "./codes";
import ImportCodeProduct from "./products/components/ImportCode";
import Tasks from "./tasks";
import Themes from "./settings/themes";
import Groups from "./settings/groups";
import SettingWebConfig from "./settings/web-config";
import ComingSoon from "./settings/coming-soon";
import PurchasePackage from "./purchases/packages";
import Revenues from "./revenues";
import CampaignsLevels from "./campaigns/levels";
import BuySellTrade from "./trades/buy-and-sell";
import WithdrawTradeBalance from "./trades/withdraw";
import Menus from "./settings/menus";
import Categories from "./settings/categories";
import CategoriesGroup from "./settings/categories-groups";
import Ads from "./settings/ads";
import UserDonate from "./settings/user-donate/View";
import Banks from "./banks";

const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const copyTooltipRef: any = useRef();
  const location = useLocation();
  const {
    auth,
    getStocks,
    getUsers,
    getNotifyPending,
    getMinigames,
    getSettings,
    getLocations,
  } = useContext(MasterContext);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (auth) {
      getUsers();
      getStocks();
      getMinigames();
      getSettings();
      getLocations();
    }
  }, [auth]);

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    getNotifyPending();

    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle: any) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e: any) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode: React.SetStateAction<string>) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event: any) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event: { preventDefault: () => void }) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event: { preventDefault: () => void }) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event: {
    preventDefault: () => void;
  }) => {
    mobileTopbarMenuClick = true;
    event.preventDefault();
  };

  const onMenuItemClick = (event: { item: { items: any } }) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Home",
      items: [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/",
          module: "overview",
        },
      ],
    },
    {
      label: "SYSTEMS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Support",
          icon: "bx bx-support",
          to: "/supports",
          module: "supports",
        },
        {
          label: "Uploads",
          icon: "bx bx-cloud-upload",
          to: "/uploads",
          module: "uploads",
        },
        {
          label: "Banners",
          icon: "pi pi-fw pi-list",
          to: "/banners",
          module: "banners",
        },
        {
          label: "Balances",
          icon: "pi pi-fw pi-tablet",
          to: "/balances",
          module: "users",
        },
        {
          label: "Q&A",
          icon: "bx bx-question-mark",
          to: "/qa",
          module: "settings",
        },
        {
          label: "Whitepaper",
          icon: "bx bx-paperclip",
          to: "/whitepaper",
          module: "settings",
        },
        {
          label: "Privacy Policy",
          icon: "pi pi-fw pi-bars",
          to: "/policy",
          module: "settings",
        },
      ],
    },
    {
      label: "USERS",
      items: [
        {
          label: "Users",
          icon: "bx bxs-user",
          to: "/users",
          badge: "10",
          module: "users",
        },
        {
          label: "Kycs & Verifies",
          icon: "bx bxs-universal-access",
          module: "kycs",
          items: [
            {
              label: "Kycs",
              module: "kycs",
              icon: "bx bx-list-check",
              to: "/kycs",
            },
            {
              label: "Verifies",
              module: "kycs",
              icon: "bx bx-list-check",
              to: "/verifies",
            },
          ],
        },
        {
          label: "Stocks",
          icon: "bx bxs-coin-stack",
          to: "/stocks",
          module: "cryptos",
        },
        {
          label: "Allotments",
          icon: "bx bx-add-to-queue",
          to: "/allotments",
          module: "allotments",
        },
        {
          label: "Banks card",
          icon: "pi pi-credit-card",
          to: "/banks",
          module: "banks",
        },
        {
          label: "Payments",
          icon: "bx bxl-paypal",
          module: "payments",
          items: [
            {
              label: "Deposits",
              icon: "bx bx-list-check",
              to: "/payments/deposits",
              module: "payments",
              badge: "10",
            },
            {
              label: "Withdraws",
              icon: "bx bx-list-check",
              to: "/payments/withdraws",
              badge: "10",
              module: "payments",
            },
          ],
        },
        {
          label: "Transactions",
          icon: "bx bx-transfer",
          module: "transactions",
          items: [
            {
              label: "Investments",
              icon: "bx bx-list-ul",
              to: "/transactions/investments",
              module: "transactions",
            },
            {
              label: "Profit Investments",
              icon: "bx bx-list-ul",
              to: "/transactions/profit-investments",
              module: "transactions",
            },
            {
              label: "Deposits",
              icon: "bx bx-list-ul",
              to: "/transactions/deposits",
              module: "transactions",
            },
            {
              label: "Withdraws",
              icon: "bx bx-list-ul",
              to: "/transactions/withdraws",
              module: "transactions",
            },
            {
              label: "Swaps",
              icon: "bx bx-list-ul",
              to: "/transactions/swaps",
              module: "transactions",
            },
            {
              label: "Changes",
              icon: "bx bx-list-ul",
              to: "/transactions/changes",
              module: "transactions",
            },
          ],
        },
        {
          label: "Purchases",
          icon: "bx bx-cart-add",
          module: "purchases",
          items: [
            {
              label: "Products",
              icon: "bx bx-list-ul",
              to: "/purchases/products",
              module: "purchases",
            },
            {
              label: "Packages",
              icon: "bx bx-list-ul",
              to: "/purchases/packages",
              module: "purchases",
            },
            {
              label: "Projects",
              icon: "bx bx-list-ul",
              to: "/purchases/projects",
              module: "purchases",
            },
          ],
        },
      ],
    },
    {
      label: "STOCKS",
      icon: "pi pi-fw pi-clone",
      items: [
        {
          label: "Codes",
          icon: "bx bx-git-pull-request",
          to: "/codes",
          module: "codes",
        },
        {
          label: "E.Stock Request",
          icon: "bx bx-git-pull-request",
          to: "/requests",
          module: "your-estock",
        },
        {
          label: "Categories",
          icon: "pi pi-fw pi-bars",
          to: "/settings/categories",
          module: "settings",
        },
        {
          label: "Categories Group",
          icon: "pi pi-fw pi-bars",
          to: "/settings/categories-group",
          module: "settings",
        },
        {
          label: "Packages",
          icon: "bx bx-package",
          to: "/packages",
          module: "invests",
        },
        {
          label: "Campaigns",
          icon: "pi pi-fw pi-circle-off",
          module: "campaigns",
          items: [
            {
              label: "List",
              icon: "bx bx-list-ul",
              to: "/campaigns/list",
              module: "campaigns",
            },
            {
              label: "Levels",
              icon: "bx bx-list-ul",
              to: "/campaigns/levels",
              module: "levels",
            },
            // {
            //   label: "Revenues",
            //   icon: "bx bx-list-ul",
            //   to: "/transactions/revenues",
            //   module: "revenues",
            // },
          ],
        },
        {
          label: "Revenues",
          icon: "bx bx-list-ul",
          module: "revenues",
          to: "/revenues",
        },
        {
          label: "Event",
          icon: "bx bx-calendar-event",
          to: "/events",
          module: "events",
        },
        {
          label: "Task",
          icon: "bx bx-task",
          to: "/tasks",
          module: "tasks",
        },
        {
          label: "Products",
          icon: "bx bxl-product-hunt",
          to: "/products",
          module: "products",
        },
        {
          label: "Notifications",
          icon: "bx bx-bell",
          to: "/notifications",
          module: "notifications",
        },
        {
          label: "Ads",
          icon: "pi pi-fw pi-bars",
          to: "/settings/ads",
          module: "settings",
        },
      ],
    },

    {
      label: "SETTINGS",
      icon: "pi pi-fw pi-sitemap",
      items: [
        {
          label: "Web config",
          icon: "pi pi-fw pi-bars",
          to: "/settings/web_config",
          module: "settings",
        },
        {
          label: "Landing page",
          icon: "bx bx-home",
          to: "/settings/landing-page",
          module: "settings",
        },
        {
          label: "Themes",
          icon: "pi pi-palette",
          to: "/settings/themes",
          module: "settings",
        },
        {
          label: "Stocks Price",
          icon: "pi pi-fw pi-list",
          to: "/settings/stocks-price",
          module: "settings",
        },
        {
          label: "Banks & Wallets",
          icon: "bx bx-wallet-alt",
          to: "/settings/systems",
          module: "settings",
        },
        // {
        //   label: "Channel",
        //   icon: "bx bxs-layout",
        //   to: "/settings/channels",
        //   module: "settings",
        // },
        {
          label: "Channels",
          icon: "bx bxs-layout",
          module: "settings",
          items: [
            {
              label: "Channel",
              icon: "bx bx-list-check",
              to: "/settings/channels/channel",
              module: "settings",
              badge: "10",
            },
            {
              label: "Socials",
              icon: "bx bxl-codepen",
              to: "/settings/channels/social",
              badge: "10",
              module: "settings",
            },
          ],
        },
        {
          label: "Groups",
          icon: "bx bxs-layout",
          to: "/settings/groups",
          module: "settings",
        },
        {
          label: "Referral Comission",
          icon: "bx bx-network-chart",
          to: "/settings/referrals",
          module: "settings",
        },
        {
          label: "Objects",
          icon: "bx bx-group",
          to: "/settings/objects",
          module: "settings",
        },
        {
          label: "Logo",
          icon: "bx bx-cloud-upload",
          to: "/settings/logos",
          module: "settings",
        },
        {
          label: "Menus",
          icon: "pi pi-fw pi-bars",
          to: "/settings/menus",
          module: "settings",
        },
        {
          label: "User Receive Donate",
          icon: "pi pi-fw pi-bars",
          to: "/settings/users-donate",
          module: "settings",
        },
      ],
    },
  ];

  const addClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element: HTMLElement, className: string) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        onToggleMenuClick={onToggleMenuClick}
        layoutColorMode={layoutColorMode}
        mobileTopbarMenuActive={mobileTopbarMenuActive}
        onMobileTopbarMenuClick={onMobileTopbarMenuClick}
        onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
      />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu
          model={menu}
          onMenuItemClick={onMenuItemClick}
          layoutColorMode={layoutColorMode}
        />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Dashboard colorMode={layoutColorMode} location={location} />
              )}
            />
            <Route path="/users" component={Users} />
            <Route path="/kycs" component={Kycs} />
            <Route path="/verifies" component={Verifies} />
            <Route path="/uploads" component={Uploads} />
            <Route path="/banners" component={Banners} />
            <Route path="/supports" component={Supports} />
            <Route path="/balances" component={Balances} />
            <Route path="/requests" component={StocksRequests} />
            <Route path="/stocks" component={Stocks} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/allotments" component={Allotments} />
            <Route path="/products/codes/:id" component={ImportCodeProduct} />
            <Route path="/products" component={Products} />
            <Route path="/qa" component={QuestionAndAnswer} />
            <Route path="/whitepaper" component={WhitePaper} />
            <Route path="/policy" component={Policy} />
            <Route path="/events" component={Events} />
            <Route path="/tasks" component={Tasks} />
            <Route path="/trades/buy-and-sell" component={BuySellTrade} />
            <Route path="/trades/withdraw" component={WithdrawTradeBalance} />
            <Route
              path="/transactions/investments"
              component={TransactionInvestments}
            />
            <Route
              path="/transactions/profit-investments"
              component={TransactionProfitInvestments}
            />
            <Route
              path="/transactions/deposits"
              component={TransactionDeposits}
            />
            <Route
              path="/transactions/withdraws"
              component={TransactionWithdraws}
            />
            <Route path="/transactions/swaps" component={TransactionSwaps} />
            <Route
              path="/transactions/changes"
              component={TransactionChanges}
            />
            <Route path="/banks" component={Banks} />
            <Route path="/settings/web_config" component={SettingWebConfig} />
            <Route
              path="/settings/advertisement-position"
              component={Positions}
            />
            <Route path="/settings/stocks-price" component={StocksPrice} />
            <Route path="/settings/themes" component={Themes} />
            <Route path="/settings/landing-page" component={LandingPages} />
            <Route path="/settings/referrals" component={Referrals} />
            <Route path="/settings/channels/channel" component={Channels} />
            <Route path="/settings/channels/social" component={Social} />

            <Route path="/settings/systems" component={BanksAndWallets} />
            <Route path="/settings/objects" component={SettingObjects} />
            <Route path="/settings/logos" component={Logos} />
            <Route path="/payments/deposits" component={PaymentDeposits} />
            <Route path="/payments/withdraws" component={PaymentWithdraws} />
            <Route path="/settings/groups" component={Groups} />
            <Route path="/settings/coming-soon" component={ComingSoon} />
            <Route path="/settings/menus" component={Menus} />
            <Route path="/settings/categories" component={Categories} />
            <Route path="/settings/ads" component={Ads} />
            <Route
              path="/settings/categories-group"
              component={CategoriesGroup}
            />
            <Route path="/settings/users-donate" component={UserDonate} />
            <Route path="/packages" component={Packages} />
            <Route path="/campaigns/list" component={CampaignsList} />
            <Route path="/campaigns/levels" component={CampaignsLevels} />
            <Route path="/codes" component={Codes} />
            <Route path="/purchases/packages" component={PurchasePackage} />
            <Route path="/revenues" component={Revenues} />
            <Route path="*" component={Empty} />
          </Switch>
        </div>

        <AppFooter layoutColorMode={layoutColorMode} />
      </div>

      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default App;
