/* eslint-disable react-hooks/exhaustive-deps */
import VUpload from "components/v-upload";
import { Button, InputSwitch, InputText, Panel } from "primereact";
import React, { useEffect, useImperativeHandle, useState } from "react";
import SettingService from "services/settings";
import { showToast } from "utils/common";
import { v4 as uuidv4 } from "uuid";
import Draggable from "react-draggable";

const Details = (props, ref) => {
  const { data, toast, reload, setLoading } = props;
  const [details, setDetails] = useState(null);

  const getDefaultData = () => {
    return {
      name: "",
      front: {
        background: "",
        userInputs: [],
      },
      back: {
        background: "",
        userInputs: [],
      },
      logo: {
        src: "",
        position: {
          x: 16,
          y: 16,
        },
      },
    };
  };

  useEffect(() => {
    if (props.details) {
      setDetails(props.details);
    } else {
      setDetails(getDefaultData());
    }
  }, [props.details]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const submit = async () => {
    try {
      setLoading(true);
      if (!data) {
        return await SettingService.createSetting({
          body: {
            name: "bank_cards",
            value: [details],
          },
        });
      } else {
        let _data = [];
        if (props.details) {
          _data = data.map((i) => (i._id === details._id ? details : i));
        } else {
          _data = [...data, details];
        }
        await SettingService.updateSetting({
          body: {
            name: "bank_cards",
            value: _data,
          },
        });
      }

      setLoading(false);
      showToast(toast, "success", "Saved!");
      reload();
      setDetails(null);
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const handleAddField = (type: string) => {
    setDetails((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type].userInputs?.concat({
          id: uuidv4(),
          label: "",
          value: "",
          placeholder: "",
          isEdit: false,
          font_size: "15",
          color: "#000",
          font_weight: "500",
          position: {
            x: 50,
            y: 50,
          },
        }),
      },
    }));
  };

  const handleSubField = (type: string) => {
    setDetails((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        userInputs: prev[type].userInputs.slice(
          0,
          prev[type].userInputs?.length - 1
        ),
      },
    }));
  };

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const handleChangeField = (index) => (name, value, field?) => {
    setDetails((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        userInputs: prev[field].userInputs.map((p, idx) =>
          idx === index
            ? {
                ...p,
                [name]: value,
              }
            : p
        ),
      },
    }));
  };

  return (
    <>
      <Panel header="Genaral" toggleable className="my-2">
        <div className="grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="description" className="font-semibold">
              Bank name
            </label>
            <InputText
              id="name"
              value={details?.name}
              onChange={(e) => onChange(e.target.value, "name")}
              required
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="frontBackground" className="text-center w-full">
              Front background
            </label>
            <VUpload
              urlFile={details?.front?.background}
              setUrlFile={(e) => onChange(e, "background", "front")}
              size="243x153"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="backBackground" className="text-center w-full">
              Back background
            </label>
            <VUpload
              urlFile={details?.back?.background}
              setUrlFile={(e) => onChange(e, "background", "back")}
              size="243x153"
            />
          </div>
          <div className="field col-12 md:col-4">
            <label htmlFor="logo" className="text-center w-full">
              Logo
            </label>
            <VUpload
              urlFile={details?.logo?.src}
              setUrlFile={(e) => onChange(e, "src", "logo")}
              size="42x42"
            />
          </div>

          <div className="field col-12">
            <div className="field col-12">
              <Panel header="Front" toggleable className="my-2">
                <div className="w-full flex flex-column align-items-center">
                  <label
                    htmlFor="preview"
                    className="text-center font-bold text-md w-full"
                  >
                    Preview
                  </label>
                  <div
                    className="relative border-round-3xl"
                    style={{ width: "243px", height: "153px" }}
                  >
                    <img
                      className="absolute top-0 left-0 w-full h-full rounded-lg"
                      style={{ objectFit: "cover", borderRadius: "8px" }}
                      src={details?.front?.background}
                      alt=""
                    />
                    <Draggable
                      bounds="parent"
                      position={details?.logo?.position}
                      onDrag={(e, pos) =>
                        onChange({ x: pos.x, y: pos.y }, "position", "logo")
                      }
                    >
                      <div className="w-6rem h-3rem">
                        <img
                          className="max-w-full max-h-full object-cover"
                          src={details?.logo?.src}
                          alt="logo"
                        />
                      </div>
                    </Draggable>
                    {details?.front?.userInputs?.map((item, index) => (
                      <Draggable
                        key={index}
                        // bounds="parent"
                        position={item?.position}
                        onDrag={(e, pos) =>
                          handleChangeField(index)(
                            "position",
                            {
                              x: pos.x,
                              y: pos.y,
                            },
                            "front"
                          )
                        }
                      >
                        <InputText
                          style={{
                            fontSize: `${item?.font_size}px`,
                            color: `${item?.color}`,
                            fontWeight: `${item?.font_weight}`,
                          }}
                          type="text"
                          value={item?.placeholder}
                          className="w-auto border-none bg-transparent p-2"
                          onChange={(e) =>
                            handleChangeField(index)(
                              "placeholder",
                              e.target.value,
                              "front"
                            )
                          }
                        />
                      </Draggable>
                    ))}
                  </div>
                </div>
                <div className="field col-12 md:col-12">
                  <Panel header="User input" toggleable className="my-2">
                    {details?.front?.userInputs?.map((item, index) => (
                      <Panel
                        key={index}
                        header={item?.name}
                        toggleable
                        className="my-2"
                      >
                        <div className="grid">
                          <div className="field md:col-4 col-12">
                            <label htmlFor="label">Label</label>
                            <InputText
                              id="label"
                              value={item?.label}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "label",
                                  e.target.value,
                                  "front"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="placeholder">Placeholder</label>
                            <InputText
                              id="placeholder"
                              value={item?.placeholder}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "placeholder",
                                  e.target.value,
                                  "front"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="color">Color</label>
                            <br />
                            <input
                              id="color"
                              type="color"
                              value={item?.color}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "color",
                                  e.target.value,
                                  "front"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="font_size">Font size</label>
                            <InputText
                              id="font_size"
                              value={item?.font_size}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "font_size",
                                  e.target.value,
                                  "front"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="font_weight">Font weight</label>
                            <InputText
                              id="font_weight"
                              value={item?.font_weight}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "font_weight",
                                  e.target.value,
                                  "front"
                                )
                              }
                              required
                            />
                          </div>

                          <div className="field md:col-4 col-12">
                            <label htmlFor="isEdit">Is edit</label>
                            <br />
                            <InputSwitch
                              checked={item?.isEdit}
                              onChange={(e: any) =>
                                handleChangeField(index)(
                                  "isEdit",
                                  e.value,
                                  "front"
                                )
                              }
                            />
                          </div>
                        </div>
                      </Panel>
                    ))}

                    <div className="col-12">
                      <Button
                        icon="bx bxs-add-to-queue"
                        className="p-button-rounded p-button-success"
                        aria-label="Search"
                        onClick={() => handleAddField("front")}
                      />
                      <Button
                        icon="bx bxs-minus-circle"
                        className="p-button-rounded p-button-danger"
                        aria-label="Cancel"
                        onClick={() => handleSubField("front")}
                      />
                    </div>
                  </Panel>
                </div>
              </Panel>
            </div>
            <div className="field col-12">
              <Panel header="Back" toggleable className="my-2">
                <div className="w-full flex flex-column align-items-center">
                  <label
                    htmlFor="preview"
                    className="text-center font-bold text-md w-full"
                  >
                    Preview
                  </label>
                  <div
                    className="relative"
                    style={{ width: "243px", height: "153px" }}
                  >
                    <img
                      className="absolute top-0 left-0 w-full h-full"
                      style={{ objectFit: "cover" }}
                      src={details?.back?.background}
                      alt=""
                    />
                    {details?.back?.userInputs?.map((item, index) => (
                      <Draggable
                        key={index}
                        position={item?.position}
                        onDrag={(e, pos) =>
                          handleChangeField(index)(
                            "position",
                            {
                              x: pos.x,
                              y: pos.y,
                            },
                            "back"
                          )
                        }
                      >
                        <InputText
                          style={{
                            fontSize: `${item?.font_size}px`,
                            color: `${item?.color}`,
                            fontWeight: `${item?.font_weight}`,
                          }}
                          type="text"
                          value={item?.placeholder}
                          className="w-auto border-none bg-transparent p-0"
                          onChange={(e) =>
                            handleChangeField(index)(
                              "placeholder",
                              e.target.value,
                              "back"
                            )
                          }
                        />
                      </Draggable>
                    ))}
                  </div>
                </div>
                <div className="field col-12 md:col-12">
                  <Panel header="User input" toggleable className="my-2">
                    {details?.back?.userInputs?.map((item, index) => (
                      <Panel
                        key={index}
                        header={item?.label}
                        toggleable
                        className="my-2"
                      >
                        <div className="grid">
                          <div className="field md:col-4 col-12">
                            <label htmlFor="label">Label</label>
                            <InputText
                              id="label"
                              value={item?.label}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "label",
                                  e.target.value,
                                  "back"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="placeholder">Placeholder</label>
                            <InputText
                              id="placeholder"
                              value={item?.placeholder}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "placeholder",
                                  e.target.value,
                                  "back"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="color">Color</label>
                            <br />
                            <input
                              id="color"
                              type="color"
                              value={item?.color}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "color",
                                  e.target.value,
                                  "back"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="font_size">Font size</label>
                            <InputText
                              id="font_size"
                              value={item?.font_size}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "font_size",
                                  e.target.value,
                                  "back"
                                )
                              }
                              required
                            />
                          </div>
                          <div className="field md:col-4 col-12">
                            <label htmlFor="font_weight">Font weight</label>
                            <InputText
                              id="font_weight"
                              value={item?.font_weight}
                              onChange={(e) =>
                                handleChangeField(index)(
                                  "font_weight",
                                  e.target.value,
                                  "back"
                                )
                              }
                              required
                            />
                          </div>

                          <div className="field md:col-4 col-12">
                            <label htmlFor="isEdit">Is edit</label>
                            <br />
                            <InputSwitch
                              checked={item?.isEdit}
                              onChange={(e: any) =>
                                handleChangeField(index)(
                                  "isEdit",
                                  e.value,
                                  "back"
                                )
                              }
                            />
                          </div>
                        </div>
                      </Panel>
                    ))}

                    <div className="col-12">
                      <Button
                        icon="bx bxs-add-to-queue"
                        className="p-button-rounded p-button-success"
                        aria-label="Search"
                        onClick={() => handleAddField("back")}
                      />
                      <Button
                        icon="bx bxs-minus-circle"
                        className="p-button-rounded p-button-danger"
                        aria-label="Cancel"
                        onClick={() => handleSubField("back")}
                      />
                    </div>
                  </Panel>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default React.forwardRef(Details);
